import React, { useEffect } from 'react'
import env from '../../Config'
import { Link } from 'react-router-dom'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './Styles.scss'


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <>
    <div id="carouselExampleCaptions" class="carousel slide homeCarousel" data-bs-ride="carousel">

        <div class="carousel-inner">
          {/* <div class="carousel-item ">
      <img src={env.baseUrl + '/images/web-design-bnr.jpg'} alt="banner image" class="img-fluid d-block w-100" />
      <div class="carousel-caption">
        <div className="row">
          <div className="col-lg-6 animate__animated animate__fadeInTopLeft animate__delay-1s">
            <h5>RESPONSIVE WEBSITE DESIGN</h5>
            <p class="d-none d-md-block">Unique Mind Solutions specialized in Website Designing. We've the proven expertise and experience.</p>
          </div>
        </div>
        
      </div>
    </div> */}

          <div class="carousel-item active">
            <img src={env.baseUrl + '/images/web-develoment-bnr.jpg'} alt="banner image" class="img-fluid d-block w-100" />
            <div class="carousel-caption">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center animate__animated animate__fadeInDown animate__delay-1s">
                  <h5 className='text-center'><span className='text-white'>Crafting Digital Experiences That Inspire</span> Your Vision, <span className='text-white'>Our Expertise</span></h5>
                  {/* <p class="d-none d-md-block"></p> */}
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <img src={env.baseUrl + '/images/graphic-design-bnr.jpg'} alt="banner image" class="img-fluid d-block w-100" />
            <div class="carousel-caption">
              <div className="row">
                <div className="col-lg-9 animate__animated animate__fadeInRight animate__delay-1s">
                  <h5 className='text-center'><span className='text-white'>Empower Your Online Presence</span> Innovative Solutions, <span className='text-white'>are Await</span></h5>
                  {/* <p class="d-none d-md-block">Strong visual elements and materials attract attention, communicate your message, and effectively represent your brand.</p> */}
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <img src={env.baseUrl + '/images/mobile-app-bnr.jpg'} alt="banner image" class="img-fluid d-block w-100" />
            <div class="carousel-caption">
              <div className="row">
                <div className="offset-lg-2 col-lg-8 animate__animated animate__fadeInUpBig animate__delay-1s">
                  <h5 className='text-center'><span className='text-white'>Elevate Your Efficiency</span> Transformative Software, <span className='text-white'>Tailored to You</span></h5>
                  {/* <p class="d-none d-md-block">We have more experience in mobile app development</p> */}
                </div>
              </div>

            </div>
          </div>

          {/* <div class="carousel-item">
    <img src={env.baseUrl + '/images/digital-marketing-bnr.jpg'} alt="banner image" class="img-fluid d-block w-100" />
      <div class="carousel-caption">
      <div className="row">
          <div className="col-lg-6 animate__animated animate__backInLeft animate__delay-1s">
            <h5>DIGITAL MARKETING</h5>
            <p class="d-none d-md-block">We offers advanced Digital marketing services to help you strategize your digital marketing services online</p>
          </div>
        </div>           
      </div>
    </div> */}



        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <section className='welcome-sec base-pad'>
        <div class="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-5 text-center py-5">
                <img src={env.baseUrl + '/images/welcome-img.jpg'} className='img-fluid rounded shadow' />
              </div>
              <div className="col-lg-7 ps-lg-5">

                <h6 className='sec-heading mt-5'>Welcome</h6>
                <h2 className='base-h1'>
                  "Empowering Excellence Through Innovative IT Solutions: Welcome to Unique Mind Solutions"
                </h2>

                <p>
                  At Unique Mind Solutions, we prioritize client satisfaction and strive to build long-term partnerships based on trust, transparency, and mutual success. Whether you're a small startup or a large enterprise,
                </p>

                <p>
                  we have the capabilities and resources to support your IT needs and help you achieve your business objectives
                </p>

                {/* <Link className='read-more-btn'>Read more <i class="las la-arrow-right"></i></Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='our-services base-pad'>
        <div class="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-6">
                <h6 className='sec-heading mt-5'>OUR SERVICE</h6>
                <h2 className='base-h1 pe-lg-5'>
                  We Offer a Wide Variety of IT Services
                </h2>
              </div>
              <div className="col-lg-6 mt-5 ">
                <p>
                  In today's dynamic business landscape, staying ahead requires more than just keeping up with technology it demands strategic innovation and expert execution. At Unique Mind Solutions, we offer a comprehensive suite of services designed to empower your digital success. From software development to cybersecurity solutions, our tailored offerings are crafted to meet your unique needs and drive tangible results. Explore our services below to discover how we can help you unlock your full potential and achieve your business goals
                </p>
              </div>

            </div>
            <div className="col-lg-12 mt-5 postion-relative">
              {/* <Splide aria-label="My Favorite Images">
              
              {/* slider start */}
              <Splide
                options={{
                  rewind: true,
                  gap: '20px',
                  autoplay: true,
                  type: 'loop',
                  drag: true,
                  interval: 4000,
                  perPage: 3,
                  breakpoints: {
                    640: {
                      perPage: 1,
                    },
                    992: {
                      perPage: 2,
                    },
                    1200: {
                      perPage: 3,
                    },
                  }
                }}
                aria-label="My Favorite Images"
              >

                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/web-designing-img.avif'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Web Designing</h4>
                      <p>
                        Crafting captivating websites with creativity and precision. Elevate your online presence
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/web-development-img.avif'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Web Development</h4>
                      <p>
                        Building robust websites with cutting-edge technology. Empowering your digital presence
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/mobile-development.jpg'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Mobile Development</h4>
                      <p>
                        Creating innovative mobile apps to engage and delight users. Transforming experiences
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/graphic-design.jpg'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Graphic Design</h4>
                      <p>
                        Captivating visuals crafted with creativity and precision. Elevate your brand's image
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/digital-marketing.jpg'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Digital Marketing & SEO</h4>
                      <p>
                        Strategic campaigns tailored to engage audiences and drive conversions. Maximize your reach
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/ecommerce-development.png'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Ecommerce Development</h4>
                      <p>
                      For e-commerce platforms, we offer responsive & personalised UI/UX design as part of our professional e-commerce website development.
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/Enterprise-Solutions.png'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Enterprise Solutions</h4>
                      <p>
                      Our business solutions provide your organization with the essential tools required to optimize fundamental processes, foster growth.
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
                <SplideSlide>
                  <Link to='#' className='story-slider'>
                    <img src={env.baseUrl + '/images/product-development.png'} className='img-fluid' />
                    <div className="carousel-story-content">
                      <h4 className='service-name'>Product Development</h4>
                      <p>
                      When standard software falls short, we develop tailored solutions to enable your business to achieve its maximum potential.
                      </p>
                    </div>
                  </Link>
                </SplideSlide>

              </Splide>
              {/* slider end */}
            </div>
          </div>
        </div>
      </section>
      <section className='welcome-sec base-pad mt-5'>
        <div class="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-5">
                <img src={env.baseUrl + '/images/we-deal-with.png'} className='img-fluid' />
              </div>
              <div className="col-lg-6 ps-lg-5 pt-lg-5">

                {/* <h6 className='sec-heading mt-5'>Welcome</h6> */}
                <h2 className='base-h1'>
                  We deal With The Aspects Of Professional IT Services
                </h2>

                <p>
                  Engitech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, Engitech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design,
                </p>

                <p>
                  Engitech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design,
                </p>

                {/* <Link className='read-more-btn'>Read more <i class="las la-arrow-right"></i></Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className='our-industry base-pad mt-5'>
        <div class="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h6 className='sec-heading mt-5'>Industry Solutions</h6>
                <h2 className='base-h1 text-white'>
                  How Can Help of Your Business Grow And Successful
                </h2>
              </div>
            </div>

            {/* slider start */}
            <br />
            <Splide
              options={{
                rewind: true,
                gap: '20px',
                autoplay: true,
                type: 'loop',
                drag: true,
                interval: 4000,
                perPage: 4,
                breakpoints: {
                  640: {
                    perPage: 1,
                  },
                  992: {
                    perPage: 2,
                  },
                  1200: {
                    perPage: 4,
                  },
                }
              }}
              aria-label="My Favorite Images"
            >

              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-heartbeat"></i>
                    <h5 className="indus-card-headng">Healthcare</h5>
                    <p>
                      At Unique Mind Solutions, we specialize in healthcare application development, catering to the evolving needs of patients, healthcare providers, and administrators alike
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-map-marked"></i>
                    <h5 className="indus-card-headng">Travel</h5>
                    <p>
                      Embark on seamless journeys with Unique Mind Solutions' travel applications, offering personalized itineraries, real-time updates, and unforgettable experiences for travelers worldwide
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-handshake"></i>
                    <h5 className="indus-card-headng">Consulting</h5>
                    <p>
                      Unique Mind Solutions revolutionizes consulting with bespoke applications, offering streamlined processes, data-driven insights, and unparalleled efficiency for businesses worldwide
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-tools"></i>
                    <h5 className="indus-card-headng">Manufacturing</h5>
                    <p>
                      Unique Mind Solutions revolutionizes manufacturing with custom applications, optimizing processes and boosting efficiency for industry leaders. Streamline operations effortlessly
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-cogs"></i>
                    <h5 className="indus-card-headng">Technology</h5>
                    <p>
                      Unique Mind Solutions delivers cutting-edge technology services, empowering businesses with innovative solutions to navigate digital challenges and drive success
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-file-invoice-dollar"></i>
                    <h5 className="indus-card-headng">Finance</h5>
                    <p>
                      Transform financial operations with Unique Mind Solutions' tailored applications, enhancing efficiency, security, and agility to drive success in the digital age
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-school"></i>
                    <h5 className="indus-card-headng">Education</h5>
                    <p>
                      Elevate learning experiences with Unique Mind Solutions' innovative education applications, fostering engagement, personalization, and collaboration for students and educators
                    </p>
                  </div>
                </div>
              </SplideSlide>

            </Splide>
            {/* slider end */}
          </div>
        </div>
        <br />
        <br />
      </section>

      <section className='our-Portfolio bg-light base-pad'>
        <div class="container-fluid pb-5">
          <div className="container-cust">

            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h6 className='sec-heading mt-5'>Portfolio</h6>
                <h2 className='base-h1'>
                  Explore Our Recent Projects
                </h2>
                <p>
                Showcasing our expertise, creativity, and success stories. Explore our portfolio today.
                </p>
              </div>
            </div>

            <div className="mt-5">
              <Splide
                options={{
                  rewind: true,
                  gap: '20px',
                  autoplay: true,
                  type: 'loop',
                  drag: true,
                  interval: 4000,
                  perPage: 3,
                  breakpoints: {
                    640: {
                      perPage: 1,
                    },
                    992: {
                      perPage: 2,
                    },
                    1200: {
                      perPage: 3,
                    },
                  }
                }}
                aria-label="My Favorite Images"
              >

                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://logicielglobal.com' target='_blank' className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio1_logicial.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary text-decoration-none'>www.logicialglobal.com </p>
                        <h4 className="portfoli-card-headng orange-c">Logicial Global</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://kgrinstitutes.com/' target='_blank' className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio4_kgr.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.kgrinstitutes.com</p>
                        <h4 className="portfoli-card-headng orange-c">KGR Institute</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='http://sharbox.in/' target='_blank' className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio2_sharBox.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.sharbox.in</p>
                        <h4 className="portfoli-card-headng orange-c">SharBox</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://makemyalpha.com/' target='_blank'  className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio6_makemy.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.makemyalpha.com</p>
                        <h4 className="portfoli-card-headng orange-c">Make My Alpha</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://www.empowerdatasolutions.com/' target='_blank'  className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio7_empower-data.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.empowerdatasolutions.com</p>
                        <h4 className="portfoli-card-headng orange-c"> Empower Data Solutions</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://sridevotionalhub.com/' target='_blank'  className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio5_sridevotional.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.sridevotionalhub.com</p>
                        <h4 className="portfoli-card-headng orange-c"> Sri Devotional Hub</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://meetings21.com/' target='_blank'  className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio3_meetings21.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.meetings21.com</p>
                        <h4 className="portfoli-card-headng orange-c"> Meetings21</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://yanthrik.com/' target='_blank'  className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/protfoli8_yanthrik.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.yanthrik.com</p>
                        <h4 className="portfoli-card-headng orange-c"> Yanthrik</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className='portfoli-slider'>
                    <Link to='https://aeomeds.com/' target='_blank'  className="portfoli-card">
                      <div className="portfoli-card-img">
                        <img src={env.baseUrl + '/images/portfolios/portfolio9_aeomeds.png'} className='img-fluid' alt='portfolio img' />
                      </div>
                      <div className="portfoli-card-content text-center">
                        <p className='text-secondary'>www.aeomeds.com</p>
                        <h4 className="portfoli-card-headng orange-c"> Aeomeds</h4>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
                

              </Splide>
            </div>
          </div>
        </div>
      </section>

      <section className="our-experience base-pad">
        <div class="container-fluid pb-5">
          <div className="container-cust">

            <div className="row">
              <div className="col-lg-6 pe-lg-5 mt-5">
                <h6 className='sec-heading'>OUR EXPERIENCE</h6>
                <h2 className='base-h1'>
                  Navigating Excellence: Our Experience
                </h2>
                <p>
                  Embark on a journey of unparalleled expertise and innovation with Unique Mind Solutions. With years of industry experience, we specialize in crafting tailored solutions to meet your unique needs and propel your business forward.

                </p>
                <p>
                  Trust us to navigate the complexities of technology with precision and creativity, ensuring your success every step of the way
                </p>
                <br />

                <img src={env.baseUrl + '/images/experience-img.avif'} className='img-fluid' />
              </div>


              <div className="col-lg-6 mt-5">
                <div class="row">
                  <div class="col-12 col-md-5 mt-3 position-relative">
                    <img src={env.baseUrl + '/images/experience-img1.avif'} className='img-fluid' />
                    <div className='experience-box d-none d-lg-block'>
                      <h2>20+</h2>
                      <h5>Satisfied Clients</h5>
                    </div>
                  </div>
                  <div class="col-12 col-md-5 mt-3 pt-2">
                    <img src={env.baseUrl + '/images/experience-img2.avif'} className='img-fluid' />
                  </div>
                  <div class="col-12 col-md-5 mt-3">
                    <img src={env.baseUrl + '/images/experience-img3.jpg'} className='img-fluid' />
                  </div>
                  <div class="col-12 col-md-5 mt-3 pt-2">
                    <img src={env.baseUrl + '/images/experience-img4.avif'} className='img-fluid' />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-sec" style={{ zIndex: '9' }}>
        <div className="container-fluid">
          <div className="container-cust">
            <div className="our-clients">
              <div className="row">
                <div className="col-lg-4">
                  <h6 className='sec-heading'>Our Client's</h6>
                  <h2 className='base-h1'>
                    What say's our trusted Clients
                  </h2>
                </div>
                <div className="col-lg-8 ps-lg-5">
                  <Splide
                    options={{
                      rewind: true,
                      gap: '20px',
                      autoplay: true,
                      type: 'loop',
                      drag: true,
                      interval: 4000,
                      perPage: 1,
                      breakpoints: {
                        640: {
                          perPage: 1,
                        },
                        992: {
                          perPage: 1,
                        },
                        1200: {
                          perPage: 1,
                        },
                      }
                    }}
                    aria-label="My Favorite Images"
                  >

                    <SplideSlide>
                      <p className='testi-para'>
                        "Great team to work with, they understood my requirements and helped me go live within hours, and delivered the results ahead of schedule."
                      </p>
                      <div className='client-details'>
                        <div className="client-pic">
                          <img src={env.baseUrl + '/images/avatar-iocn.png'} alt="" />
                        </div>
                        <div className="client-company">
                          <h6>Vijay</h6>
                          <p><small> Logiciel Global Service</small></p>
                        </div>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <p className='testi-para'>
                        "The management is extremely responsive too and takes feedback very seriously. They work with you closely to ensure positive results. I highly recommend UMS."
                      </p>
                      <div className='client-details'>
                        <div className="client-pic">
                          <img src={env.baseUrl + '/images/avatar-iocn.png'} alt="" />
                        </div>
                        <div className="client-company">
                          <h6>Saidulu</h6>
                          <p><small>Meetings21</small></p>
                        </div>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <p className='testi-para'>
                        "I really appreciate the creativity and hard work of UMS. Everything was professional and high quality. It would be better if it could have been finalized at the project due date. Anyway I see it like thorn of a rose and totally acceptable in this kind of real hard projects. Thank you UMS and see you at the next project!"
                      </p>
                      <div className='client-details'>
                        <div className="client-pic">
                          <img src={env.baseUrl + '/images/avatar-iocn.png'} alt="" />
                        </div>
                        <div className="client-company">
                          <h6>Dr. P.V.Ramana Rao</h6>
                          <p><small>KGR Institute of Technology & Management</small></p>
                        </div>
                      </div>
                    </SplideSlide>


                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-clients base-pad" style={{ zIndex: '0' }}>
        <div className="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">

                <h6 className='sec-heading'>Our Clients</h6>
                <h2 className='base-h1'> Trusted by the leading companies </h2>
              </div>

              <div className="col-lg-8 offset-lg-2 ps-lg-5 mt-5">
                <Splide
                  options={{
                    rewind: true,
                    gap: '20px',
                    autoplay: true,
                    type: 'loop',
                    drag: true,
                    interval: 4000,
                    perPage: 4,
                    breakpoints: {
                      640: {
                        perPage: 1,
                      },
                      992: {
                        perPage: 3,
                      },
                      1200: {
                        perPage: 4,
                      },
                    }
                  }}
                  aria-label="My Favorite Images"
                >

                  <SplideSlide>
                    <div className='clients-logo'>
                      <img src={env.baseUrl + '/images/client-logos1.jpg'} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className='clients-logo'>
                      <img src={env.baseUrl + '/images/client-logos2.jpg'} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className='clients-logo'>
                      <img src={env.baseUrl + '/images/client-logos3.jpg'} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className='clients-logo'>
                      <img src={env.baseUrl + '/images/client-logos4.png'} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className='clients-logo'>
                      <img src={env.baseUrl + '/images/client-logos5.jpg'} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className='clients-logo'>
                      <img src={env.baseUrl + '/images/client-meetings21.png'} alt="" />
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="enqery-form base-pad">
        <div className="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">

                <h6 className='sec-heading'>CONTACT US</h6>
                <h2 className='base-h1'>We Alway Here To Helps You</h2>
              </div>
            </div>
          </div>
        </div>
      </section> */}



    </>
  )
}

export default Home